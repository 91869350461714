import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { DataTableFilter } from './DataTableFilter';
import { Loader } from './Loader';
import { useTranslation } from "react-i18next";

export const DataTable = ({
    columns,
    tableSize,
    rawData,
    defaultSorted,
    dependencyArray,
    isLoading,
}) => {
    const { t } = useTranslation();
    const [filteredData, setFilteredData] = useState(rawData);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: {
                pageIndex: 0,
                pageSize: 50,
                sortBy: defaultSorted ? defaultSorted : [],
            },
        },
        useSortBy,
        usePagination
    );

    // Memoize filtered data
    useMemo(() => {
        setFilteredData(rawData);
    }, [rawData]);

    // Render loader if loading
    if (isLoading) {
        return <Loader />;
    }

    // Render no records if no data
    if (rawData.length === 0) {
        return <p className="text-center">{t("Kayıt yoktur.")}</p>;
    }

    return (
        <>
            <div className="row d-flex justify-content-between mb-4">
                <div className="col col-sm-3 col-md-3">
                    <select
                        className="form-control form-control-sm form-select"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize} {t("satır")}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col col-sm-3 col-md-3">
                    <DataTableFilter
                        rawData={rawData}
                        setFilteredData={setFilteredData}
                        dependencyArray={dependencyArray}
                    />
                </div>
            </div>
            <Table
                {...getTableProps()}
                responsive
                borderless
                striped
                hover
                size={tableSize}
            >
                <thead className="text-dark">
                    {headerGroups.map((headerGroup) => (
                        <tr
                            className="mb-2"
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.isSortable
                                            ? column.getSortByToggleProps()
                                            : ''
                                    )}
                                    title={column.render('Header')}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSortable ? (
                                            !column.isSorted ? (
                                                <i className="bi bi-chevron-expand"></i>
                                            ) : column.isSortedDesc ? (
                                                <i className="bi bi-chevron-up"></i>
                                            ) : (
                                                <i className="bi bi-chevron-down"></i>
                                            )
                                        ) : null}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Pagination className="float-right" size="sm">
                <Pagination.First
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                />
                <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                />
                <Pagination.Item
                    onClick={() => gotoPage(pageIndex)}
                    active
                >
                    {pageIndex + 1} / {pageCount}
                </Pagination.Item>
                <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                />
                <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                />
            </Pagination>
        </>
    );
};
