import React, { useState,useEffect } from 'react';
import { DataTable } from './components/DataTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MailOrder() {
    const [orders, setOrders] = useState([]);
    const [lastSync, setLastSync] = useState(null);

    useEffect(() => {
        getRecordDate(); // Bileşen yüklendiğinde getRecordDate() fonksiyonunu çağır
        const intervalId = setInterval(getRecordDate, 10000); // Her 30 saniyede bir getRecordDate() fonksiyonunu tekrar çağır

        return () => clearInterval(intervalId); // Unmount olduğunda interval temizlenir
    }, []);


    function handleFilterFormSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const startDate = formData.get('startDate');
        const endDate = formData.get('endDate');
        const storeName = formData.get('storeName');

        fetch(`http://api.ssilhouttemailmarketing.net.tr:8080/get-orders?startDate=${startDate}&endDate=${endDate}&storeName=${storeName}`)
            .then(response => response.json())
            .then(data => {
                if (data === null) { // Veri boşsa toast mesajı
                    toast.info('Sipariş yok');
                } else {
                    setOrders(data);
                    downloadExcel(data);
                    toast.success('İşlem başarılı');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Beklenmeyen bir hata oluştu');
            });
    }

    function handleFetchButtonClick() {
        fetchOrders();
    }

    function fetchOrders() {
        toast.success('İşlem Başladı');
        fetch('http://api.ssilhouttemailmarketing.net.tr:8080/fetch-orders')
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Beklenmeyen bir durum oluştu: Durum Kodu ' + response.status);
            }
        })
        .then(data => {
            console.log(data);
            setOrders(data);
            toast.success('İşlem başarılı.');
        })
        .catch(error => {
            console.error('Error:', error);
            console.log(error);
            toast.success('İşlem başarılı!');
        });
    }

    function getRecordDate() {
        fetch('http://api.ssilhouttemailmarketing.net.tr:8080/get-lastdate')
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Beklenmeyen bir durum oluştu: Durum Kodu ' + response.status);
                }
            })
            .then(data => {
                console.log(data);
                const formattedDate = data?.formattedCreationDate; // Veriden tarihi al
                setLastSync(formattedDate); // Son eşitleme tarihini ayarla
            })
            .catch(error => {
                console.error('Error:', error);
                console.log(error);
                toast.error('Hata Son eşitleme çekilemedi');
            });
    }
    

    function downloadExcel(data) {
        const emails = data.map(row => row.Email);
        const csvContent = "data:text/csv;charset=utf-8," + emails.join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "emails.csv");
        document.body.appendChild(link);
        link.click();
    }

    return (
        <div>
            <ToastContainer />
            <h2>Siparişleri Eşitle</h2>
            <div className="form-row">
                <div className="input-container submit-container">
                    <button onClick={handleFetchButtonClick}>Eşitle</button>
                </div>
            </div>
            <div className='row'>
                <span>Son Eşitleme: {lastSync}</span> {/* Son eşitleme tarihini ekrana bastır */}
            </div>
            <div className="container">
                <form onSubmit={handleFilterFormSubmit} id="filter-form">
                    <h2>Siparişleri Listele</h2>
                    <div className="form-row">
                        <div className="input-container">
                            <span className="input-header">Sipariş Başlangıç</span>
                            <input type="date" id="startDate" name="startDate" />
                        </div>
                        <div className="input-container">
                            <span className="input-header">Sipariş Bitiş</span>
                            <input type="date" id="endDate" name="endDate" />
                        </div>
                        <div className="input-container">
                            <span className="input-header">Mağaza Seç</span>
                            <select id="storeName" name="storeName">
                                <option value="">Seçiniz</option>
                                <option value="958758">SsilhoutteGemstoneTR</option>
                                <option value="1002673">FloorCushionCO</option>
                                <option value="947878">Manuel Orders</option>
                                <option value="988836">SilhoutteJewelryGift</option>
                                <option value="980843">ssilhoutte_gemstone</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-container submit-container">
                            <input type="submit" value="Filtrele" className='test'/>
                        </div>
                    </div>
                </form>
            </div>
            <div id="data-container app-container">
                <div className="row">
                    <div className="col">
                        {Array.isArray(orders) && orders.length > 0 ? ( // orders bir dizi mi ve dolu mu kontrol edilir
                            <DataTable
                                columns={[
                                    { Header: 'Sıra', accessor: 'index' },
                                    { Header: 'Mağaza ID', accessor: 'ShipEntegraStoreID' },
                                    { Header: 'Mağaza Adı', accessor: 'StoreName' },
                                    { Header: 'Mail', accessor: 'Email' },
                                ]}
                                tableSize="sm"
                                rawData={orders}
                                defaultSorted={[{ id: 'index', desc: false }]}
                                dependencyArray={[orders]}
                                isLoading={false}
                            />
                        ) : (
                            <p>Kayıt Yok...</p> // Eğer veri yoksa veya yükleniyor ise bu mesaj gösterilir
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MailOrder;
