import React from "react";
import { Spinner } from 'react-bootstrap'

  export const Loader = ({spinnerType,size,label}) => {

    return (
      <div className="d-flex justify-content-center align-items-center" >
        <Spinner 
          animation="border"
          size={size}
          style={spinnerType === "button" ? {} : {position:"absolute",top:"50%",left:"50%"}}
        />
        <span className="ml-2">{label}</span>

      </div>

    );
  };

