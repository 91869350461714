// App.js

import React from 'react';
import MailOrder from './MailOrder';

function App() {
  return (
    <div>
      <MailOrder />
    </div>
  );
}

export default App;
