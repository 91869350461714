import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

export const DataTableFilter  = ({dependencyArray, rawData, setFilteredData}) => {
        const { t} = useTranslation();

        const [query, setQuery] = useState("")
    
        const filter = () => {
            const columns = Object.keys(rawData[0])
                return rawData.filter(
                    (row)=> columns.some(
                        (column)=> row[column].toString().toLowerCase().indexOf(query.toLowerCase()) > -1))
            }


        useEffect(()=>{
            setFilteredData(filter())
        },[...dependencyArray, query])
        

         return (
             <div>
              <input   
                    className="form-control form-control-sm search "
                    placeholder={t("Filtrele...")}
                    type="text" 
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }}/>
             </div>
         )
     }